import { IState } from 'appRedux/createStore';
import { ICasinoSchema } from 'appRedux/models/casinoModels';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export enum WHITE_LABEL_TYPE_ENUM {
  CANVAS = 'CANVAS',
  BUTTON = 'BUTTON',
  ICON = 'ICON',
}

export type WhiteLabelType = keyof typeof WHITE_LABEL_TYPE_ENUM;

const useWhiteLabelStyles = (type: WhiteLabelType) => {
  const casinoSchema = useSelector<IState>((state) => state.app.persistedState.casinoSchema);
  const [styles, setStyles] = useState({});

  const whiteLabelSchemaHandler = (schema: ICasinoSchema) => {
    if (schema && schema.styles) {
      switch (type) {
        case WHITE_LABEL_TYPE_ENUM.CANVAS:
          setStyles({
            backgroundColor: schema.styles.backgroundColor,
          });
          break;
        case WHITE_LABEL_TYPE_ENUM.BUTTON:
          setStyles({
            color: schema.styles.color,
          });
          break;
        case WHITE_LABEL_TYPE_ENUM.ICON:
          setStyles({
            color: schema.styles.secondaryColor,
          });
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    let isIgnore = false;
    if (!isIgnore) {
      whiteLabelSchemaHandler(casinoSchema as ICasinoSchema);
    }
    return () => {
      isIgnore = true;
    };
  }, [casinoSchema]);

  return styles;
};

export default useWhiteLabelStyles;
