import { FC } from 'react';
import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import { close, gitCompareOutline } from 'ionicons/icons';

interface CompareColumnProps {
  selectedSlots: number[];
  isCompare: boolean;
  onCompareSlot: () => void;
  clearComparedSlots: () => void;
}

const CompareView: FC<CompareColumnProps> = ({
  selectedSlots,
  isCompare,
  onCompareSlot,
  clearComparedSlots,
}) => {
  return (
    <IonRow>
      <IonCol size="12" className="ion-text-center">
        {selectedSlots.length > 0 && (
          <>
            {!isCompare && (
              <IonButton size="small" onClick={onCompareSlot}>
                <IonIcon slot="start" icon={gitCompareOutline}></IonIcon>
                Compare Slots
              </IonButton>
            )}
            <IonButton size="small" fill="outline" onClick={clearComparedSlots}>
              <IonIcon slot="start" icon={close}></IonIcon>
              Clear Slots
            </IonButton>
          </>
        )}
      </IonCol>
    </IonRow>
  );
};

export default CompareView;
