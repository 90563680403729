import { IonButton, IonGrid, IonIcon, IonImg, useIonToast } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';

import { IManageCustomerResponse, StripeService } from 'services/stripe';
import { useSelector } from 'react-redux';
import { IState, IUserInfo } from 'appRedux/createStore';

import useGenerateAlias from './hooks/useGenerateAlias';
import Toggle from '../../components/Toggle/Toggle';
import { toastConfig, ToastType } from '../../config/ToastConfig';
import { getSubscriptionNameAndLogo } from '../../common/common';

import ProfileMenuOption from './ProfileMenuOption';
import './manage-profile.scss';
import SmartLoading from 'components/SmartLoading';
import { reloadOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';

const UserProfileMenu: React.FC = () => {
  const service = new StripeService();
  const customer_id = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.customer_id
  ) as string;
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;
  const subscriptionId = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.subscription_id
  ) as string;
  const currentPeriodEnd = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.current_period_end
  ) as string;

  const canceledAt = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.canceled_at
  ) as string;
  const shouldShowTurnOffAutoRenew = !canceledAt && isProUser;

  const { generateNewAlias, updateUserDataPublicVisible, isAliasLoading } = useGenerateAlias();
  const { aliasInitial2, aliasName, isPublicVisible } = useSelector<IState>(
    (state) => state.app.persistedState.userInfo
  ) as IUserInfo;
  const [prevIsPublicVisible, setPrevIsPublicVisible] = useState(isPublicVisible);

  useEffect(() => {
    setPrevIsPublicVisible(isPublicVisible);
  }, [isPublicVisible]);

  const [presentToast] = useIonToast();
  const history = useHistory();
  const location = useLocation();

  const showToast = ({ type = 'success', message }: { type?: ToastType; message: string }) => {
    toastConfig.color = type;
    toastConfig['message'] = message;
    void presentToast(toastConfig);
  };

  const handlePrivacyChange = (value: boolean) => {
    if (prevIsPublicVisible !== value) {
      updateUserDataPublicVisible(value);
      setPrevIsPublicVisible(value);
    }
  };

  const goToManageSubscription = () => {
    if (customer_id) {
      service.ManageSubscriptionSession(customer_id).subscribe({
        next(data: void | IManageCustomerResponse) {
          const url = data?.url || undefined;
          if (url) {
            window.open(url, '_self');
          }
        },
        error(err) {
          console.log('ERROR', err);
        },
      });
    } else {
      showToast({ type: 'danger', message: 'No Customer Record Found!' });
    }
  };

  const goToCancelSubscription = () => {
    if (customer_id) {
      service.CancelSubscriptionSession(customer_id, subscriptionId).subscribe({
        next(data: void | IManageCustomerResponse) {
          const url = data?.url || undefined;
          if (url) {
            window.open(url, '_self');
          }
        },
        error(err) {
          console.log('ERROR', err);
        },
      });
    } else {
      showToast({ type: 'danger', message: 'No Customer Record Found!' });
    }
  };

  const goToStripe = () => {
    history.push('/auth/stripe', { previousPage: location.pathname });
  };

  const goToChangePassword = () => {
    history.push('/changepassword');
  };
  const goToEditProfile = () => {
    history.push('/editprofile');
  };
  const goToMyCasinos = () => {
    history.push('/myPlayerCards');
  };
  const goToUpgradePlan = () => goToStripe();
  const { logo: subscriptionLogo } = getSubscriptionNameAndLogo(isProUser);
  const isFounder = useSelector<IState>((state) =>
    state.app.persistedState.userInfo?.groups?.includes('Founder')
  );

  return (
    <IonGrid className="manage-profile">
      <SmartLoading loading={isAliasLoading} />
      <div className="manage-profile-header">
        <div className={isFounder ? 'gold-border-wrap' : ''}>
          <div className="manage-profile-header-avatar">
            {aliasInitial2}
            {isProUser && (
              <IonImg
                src="assets/images/SlotPro.png"
                className="manage-profile-header-avatar-logo"
              />
            )}
            {isProUser && (
              <IonImg src={subscriptionLogo} className="manage-profile-header-avatar-logo" />
            )}
          </div>
        </div>
        <div className="manage-profile-user-name"> {aliasName}</div>
        <IonButton
          className="auto-generate-alias-name"
          onClick={generateNewAlias}
          disabled={isPublicVisible ? false : true}
        >
          <IonIcon icon={reloadOutline} className="reload-icon-alias" />
          <span className="reload-name-alias">AUTO-GENERATE ALIAS</span>
        </IonButton>
        <Toggle
          labelOff="Private"
          labelOn="Public"
          onChange={handlePrivacyChange}
          isChecked={isPublicVisible}
        />
        {isProUser && (
          <>
            <div className="subscribe-valid-info margin-top-10px">Subscribed to Slot Pro</div>
            {!shouldShowTurnOffAutoRenew && (
              <div className="subscribe-valid-info">
                Subscription valid till:{' '}
                {moment.unix(parseInt(currentPeriodEnd)).format('MMMM DD, YYYY')}
              </div>
            )}

            {shouldShowTurnOffAutoRenew && (
              <div className="subscribe-valid-info">
                Your Subscription plan automatically renew on:{' '}
                {moment.unix(parseInt(currentPeriodEnd)).format('MMMM DD, YYYY')}
              </div>
            )}
          </>
        )}
      </div>
      <div className="manage-profile-menu">
        <ProfileMenuOption
          optionImageSrc="assets/images/upgrade-star.svg"
          optionLabel={isProUser ? 'Active Plan' : 'Upgrade to PRO'}
          onClick={goToUpgradePlan}
        />
        {isProUser && (
          <ProfileMenuOption
            optionImageSrc="assets/images/manage-subscription-icon.svg"
            optionLabel="Manage Subscription"
            onClick={goToManageSubscription}
          />
        )}
        <ProfileMenuOption
          optionImageSrc="assets/images/dollar-icon.svg"
          optionLabel="My Player Cards"
          onClick={goToMyCasinos}
        />
        <ProfileMenuOption
          optionImageSrc="assets/images/user-icon.svg"
          optionLabel="Edit Profile"
          onClick={goToEditProfile}
        />
        <ProfileMenuOption
          optionImageSrc="assets/images/lock-icon.svg"
          optionLabel="Change Password"
          onClick={goToChangePassword}
        />
        {!canceledAt && (
          <ProfileMenuOption
            optionImageSrc="assets/images/manage-subscription-icon.svg"
            optionLabel="Turn Off Auto Renew"
            onClick={goToCancelSubscription}
          />
        )}
      </div>
    </IonGrid>
  );
};

export default UserProfileMenu;
