import Bathrooms from './mapicons/Bathrooms.png';

export const RWCIconArr = [
  {
    name: 'Bathrooms',
    image: Bathrooms,
  },
];

export const loadImage = (mapbox: any, imageName: any, key: any) => {
  const img = new Image();
  img.src = imageName;

  img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;

    const ctx = canvas.getContext('2d', { willReadFrequently: true }); // Set the flag
    if (ctx) {
      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      mapbox.addImage(key, imageData);
    }
  };

  img.onerror = (error) => {
    console.error('Error loading image:', error);
  };
};

export const addMapRWCImage = (mapbox: any) => {
  for (let index = 0; index < RWCIconArr.length; index++) {
    const eachElement = RWCIconArr[index];
    loadImage(mapbox, eachElement.image, eachElement.name);
  }
};
