import { setupIonicReact } from '@ionic/react';
import { Provider } from 'react-redux';
import createStore from 'appRedux/createStore';
const store = createStore();


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useEffect, useState } from 'react';

import { mainKaPloint } from './common/PubSub';
import { SlotCheckRouter } from 'components/SlotCheckRouter';
import baseEnv from 'config/config';
import { Amplify } from 'aws-amplify';

setupIonicReact();

interface ICurLocationResponse {
  postal?: string;
  city?: string;
}
const App: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [inactiveTimeout, setInactiveTimeout] = useState<NodeJS.Timeout | null>(null);
  const [getLocationChecked, setGetLocationChecked] = useState(false);
  const [locationData, setLocationData] = useState<ICurLocationResponse>({});
  
  let logoutTimeoutID: any; // will be used to track the session timeout instance
  const timeoutDuration = 35*60000; // 35 minutes by multiply the time value by 60000
  
  
  mainKaPloint.subscribe((loading: any) => {
    setLoading(loading as boolean);
  });

  function getCurrentZipCode(): Promise<ICurLocationResponse> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', 'https://ipapi.co/json/', true);

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300 && xhr.responseText) {
          const response = JSON.parse(xhr.responseText) as object;
          resolve(response);
        } else {
          reject(new Error(xhr.statusText));
        }
      };

      xhr.onerror = () => {
        reject(new Error('Request failed'));
      };

      xhr.send();
    });
  }

  function logoutTimeOut() {
    // Perform logout or other actions here 
    console.log("User is inactive, logging out...");
    window.location.pathname = "/logout";
    return;
  }

  function resetLogoutTracker(event?: any) {
    try {
      // if (event) {
      //   console.log('session reset:', new Date(), `timeoutDuration: ${timeoutDuration}`, `event: ${event}`);
      // } else {
      //   console.log('session reset:', new Date(), `timeoutDuration: ${timeoutDuration}`, `event - no event`);
      // }
      if (window.location.pathname === '/') { // on starting location no need to activate the session
        //console.log('at base route:', new Date());
        return;
      }

      //console.log('session created:', new Date(), `timeoutDuration: ${timeoutDuration}`);
      clearTimeout(logoutTimeoutID);
      logoutTimeoutID = setTimeout(logoutTimeOut, timeoutDuration);
      setInactiveTimeout(logoutTimeoutID);
    } catch (error) {
      console.error('Error resetting logout tracker:', error);
    }
    
    return;
  }

  useEffect(() => {
    Amplify.configure(baseEnv().amplify);
  },[]);

  useEffect(() => {
    console.log('session managed on:', new Date(), ` via component timeoutDuration: ${timeoutDuration}`);
    // Reset timer on initial render
    resetLogoutTracker();
  
    // Add event listeners to reset the timer on user activity
    window.addEventListener('mousedown',  (event) => {
      resetLogoutTracker('mousedown');
    });
    // window.addEventListener('keydown',  (event) => {
    //   resetLogoutTracker('keydown');
    // });
    // window.addEventListener('click',  (event) => {
    //   resetLogoutTracker('click');
    // });
    window.addEventListener('scroll',  (event) => {
      resetLogoutTracker('scroll');
    });
  
    // Cleanup function to clear timeout and remove event listeners
    return () => {
      if (logoutTimeoutID) {
        clearTimeout(logoutTimeoutID);
      }
      window.removeEventListener('mousemove', resetLogoutTracker);
      //window.removeEventListener('keydown', resetLogoutTracker);
      //window.removeEventListener('click', resetLogoutTracker);
      window.removeEventListener('scroll', resetLogoutTracker);
    };
  },[]);

  useEffect(() => {
    let isIgnore = false;

    if (!isIgnore && getLocationChecked) {
      getCurrentZipCode()
        .then((data) => {
          console.log('getCurrentZipCode', data);
          setLocationData(data);
        })
        .catch((err) => console.log('getCurrentZipCode ERR', err));
    }
    return () => {
      isIgnore = true;
    };
  }, [getLocationChecked]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Provider store={store}>
        <SlotCheckRouter />
      </Provider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
