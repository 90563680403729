import { ICasinoReport } from 'appRedux/models/casinoModels';
import { config, HeatBeanObjectKey } from '../config/config';

import { InsightType } from './common';
const { heatBeanRatings } = config;

export const numberRegex = /^[0-9]+$/;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
export const passwordRequirements =
  'Password must include a number, an uppercase letter, a lowercase letter, and a special character (= + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `)';
export const passwordSubtitle =
  'Password must include a number, an uppercase letter, a lowercase letter, and a special character, and be at least 8 characters long';

export const INVALID_OTP_CODE_MESSAGE = 'Invalid code provided, please request a code again.';
export const INVALID_OTP_CODE_MESSAGE2 = 'Invalid verification code provided, please try again.';
export const SHORT_PASSWORD_MESSAGE =
  'Password does not conform to policy: Password not long enough';
export const MUST_HAVE_LOWERCASE_CHAR_MESSAGE =
  'Password does not conform to policy: Password must have lowercase characters';
export const MUST_HAVE_UPPERCASE_CHAR_MESSAGE =
  'Password does not conform to policy: Password must have uppercase characters';
export const MUST_HAVE_SYMBOL_CHAR_MESSAGE =
  'Password does not conform to policy: Password must have symbol characters';
export const ATTEMPS_LIMIT_EXCEEDED_MESSAGE = 'Attempt limit exceeded, please try after some time.';
export const nameRegex = /^([A-Za-z/g]+\s)*[A-Za-z/g]+$/;
export const nameRequirement = 'Invalid characters';
export const zipcodeRegex = /^(\d{5}(\d{4})?)?$/;

export const getCurrentYear = () => {
  return new Date().getFullYear().toString();
};

export const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  const phoneNumber = value.toString().replace(/[^\d]/g, '');
  if (phoneNumber.length < 4) {
    return phoneNumber;
  } else if (phoneNumber.length < 10) {
    return phoneNumber.replace(
      /(\d{1,3})(\d{1,3})?(\d{1,4})?/,
      (_: string, p1: string, p2?: string, p3?: string) => {
        return `${p1}${p2 ? '-' + p2 : ''}${p3 ? '-' + p3 : ''}`;
      }
    );
  }
  if (phoneNumber.length === 10) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  }
  if (phoneNumber.length === 11 && phoneNumber[0] === '1') {
    return `1-(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7)}`;
  }
  return phoneNumber;
};

export const titleCase = (str: string): string => {
  if (str === null || str === '') return '';
  else str = str.toString();

  str = str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  return str.replace(/\/[a-z]*/g, function (txt: string) {
    return txt.charAt(0) + txt.charAt(1).toUpperCase() + txt.substr(2);
  });
};

export const formatNumber: any = (value: number | string) => {
  let returnValue = '0';
  if (Number.isFinite(value)) {
    returnValue = value.toString();
  }
  if (typeof value === 'string') {
    returnValue = value;
  }
  // Add commas
  return returnValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatRoundOffNumber: any = (value: number) => {
  let returnValue = '0';
  if (value) {
    returnValue = Math.round(value).toString();
  }
  // Add commas
  return returnValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPercentage: any = (value: number) => {
  let returnValue = '0';

  if (value) {
    returnValue = (value * 100).toFixed(0);
  }

  // Add commas
  returnValue = returnValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%';
  // Remove trailing zero
  returnValue = returnValue.replace('.0%', '%');
  return returnValue;
};

export const formatPercentageWithOutPercent: any = (value: number) => {
  let returnValue = '0';

  if (value) {
    returnValue = value.toFixed(0);
  }

  // Add commas
  returnValue = returnValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%';
  // Remove trailing zero
  returnValue = returnValue.replace('.0%', '%');

  return returnValue;
};

export const formatCurrency: any = (value: string): string => {
  let numDecimals = 2;
  let returnValue = '0';
  if (value !== undefined && value !== null) {
    if (parseFloat(value) >= 10) {
      numDecimals = 0;
    }
    returnValue = parseFloat(value).toFixed(numDecimals);
  }

  // Add commas
  return '$' + returnValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const kFormatter: any = (num: number) => {
  if (num > 999 && num < 1000000) {
    return Math.round(num / 1000).toString() + 'K'; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return Math.round(num / 1000000).toString() + 'M'; // convert to M for number from > 1 million
  } else if (num < 900) {
    return Math.round(num); // if value < 1000, nothing to do
  }
};

export const parseValueForTrends = (value: any, selectedInsight: string): string | number => {
  let parsedValue;
  let flooredValue = 0;
  switch (selectedInsight) {
    case 'jackpot_value':
    case 'money_won':
    case 'money_played':
    case 'avg_money_won_per_spin':
      flooredValue = Math.floor(+value);
      parsedValue = value
        ? value > 999
          ? `${formatCurrency(flooredValue)}`
          : `${formatCurrency(value)}`
        : 'N/A';
      break;
    default:
      parsedValue = value ? parseValue(value, selectedInsight) : 'N/A';
      break;
  }

  return parsedValue;
};
export const parseValueForTrendsNumber = (
  value: string | number,
  selectedInsight: string
): string | number => {
  let parsedValue: string | number;
  switch (selectedInsight) {
    case 'jackpot_value':
    case 'money_won':
    case 'money_played':
    case 'avg_money_won_per_spin':
      const flooredValue = Math.floor(+value) / 1000;
      parsedValue = value ? (value > 999 ? flooredValue.toFixed(1) : parseFloat(value as any)) : 0;
      break;
    case 'volatility_rating':
    case 'sleeper_slot_rating':
      parsedValue = value ? parseFloat(value as any) : 0;
      break;
    default:
      parsedValue = value ? parseFloat(value as any) : 0;
      break;
  }

  return parsedValue;
};
export const parseValue: any = (value: number, selectedInsight: any) => {
  let numericValue;
  switch (selectedInsight) {
    case 'money_won':
      numericValue = formatCurrency(value);
      break;
    case 'avg_money_won_per_spin':
      numericValue = formatCurrency(value);
      break;
    case 'money_won_since_jackpot':
      numericValue = formatCurrency(value);
      break;
    case 'money_played':
      numericValue = formatCurrency(value);
      break;
    case 'money_played_since_jackpot':
      numericValue = formatCurrency(value);
      break;
    case 'jackpot_value':
      numericValue = formatCurrency(value);
      break;
    case 'pop':
      numericValue = formatPercentageWithOutPercent(value);
      break;
    case 'win_rate':
      numericValue = formatPercentage(value);
      break;
    case 'return':
      numericValue = formatPercentage(value);
      break;
    case 'volatility_rating':
    case 'sleeper_slot_rating':
      if (value >= 1 && value <= 6) {
        numericValue = value
          ? heatBeanRatings[value as HeatBeanObjectKey].text_lbl_volatility
          : 'N/A';
      } else {
        numericValue = typeof value === 'undefined' ? 'N/A' : formatRoundOffNumber(value);
      }
      break;
    default:
      numericValue = formatNumber(value);
      break;
  }
  return numericValue;
};

export const parseValueGraph: any = (value: any, selectedInsight: any) => {
  let numericValue;
  switch (selectedInsight) {
    case 'money_won':
      numericValue = formatCurrency(value);
      break;
    case 'avg_money_won_per_spin':
      numericValue = formatCurrency(value);
      break;
    case 'money_won_since_jackpot':
      numericValue = formatCurrency(value);
      break;
    case 'money_played':
      numericValue = formatCurrency(value);
      break;
    case 'money_played_since_jackpot':
      numericValue = formatCurrency(value);
      break;
    case 'jackpot_value':
      numericValue = formatCurrency(value);
      break;
    case 'pop':
      numericValue = formatPercentageWithOutPercent(value);
      break;
    case 'win_rate':
      numericValue = formatPercentage(value);
      break;
    case 'return':
      numericValue = formatPercentage(value);
      break;
    case 'volatility_rating':
    case 'sleeper_slot_rating':
      numericValue = typeof value === 'undefined' ? 'N/A' : formatRoundOffNumber(value);
      break;
    default:
      numericValue = formatNumber(value);
      break;
  }
  return numericValue;
};

export const getHeatBean: any = (selectedSlotData: ICasinoReport, insightKey: InsightType) => {
  let stats;
  let color;
  if (Object.prototype.hasOwnProperty.call(selectedSlotData, insightKey + '_ntile')) {
    stats = selectedSlotData[(insightKey + '_ntile') as keyof ICasinoReport];
    color = heatBeanRatings[stats as HeatBeanObjectKey].color;
  }

  //const color = heatBeanRatings[stats as HeatBeanObjectKey].color;
  return color;
};

export const centDollarConversion: any = (value: string) => {
  let returnValue = '0';
  if (value !== undefined && value !== null) {
    returnValue = (parseFloat(value) / 100 || 0).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

  return returnValue;
};

export const getColorInsights: any = (insightValue: string) => {
  const insightColor: string = config.slotInsights.find((insight) => insight.value === insightValue)
    ?.backgroundColor!;
  const inlineStyle = { ['color' as any]: insightColor };
  return inlineStyle;
};

export const maskEmailAddress = (emailAddress: string) => {
  function mask(str: string) {
    const strLen = str.length;
    if (strLen > 4) {
      return str.substr(0, 1) + str.substr(1, strLen - 1).replace(/\w/g, '*') + str.substr(-1, 1);
    }
    return str.replace(/\w/g, '*');
  }
  return emailAddress.replace(/([\w.]+)@([\w.]+)(\.[\w.]+)/g, function (m, p1, p2, p3) {
    return mask(p1) + '@' + mask(p2) + p3;
  });
};

export const validateEmail = (email = '') => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
