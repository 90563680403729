import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  IonCol,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IState, ITabProperties, useAppSelector } from 'appRedux/createStore';
import { ICasinoReport, ICasinoSchema } from 'appRedux/models/casinoModels';
import HotListReport from './HotListReport/HotListReport';
import FloorMap from 'components/FloorMapPackage/components/floorMap';
import clsx from 'clsx';
import { InsightValue } from 'common/types';
import DropdownBar from 'components/DropdownBar/DropdownBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { config } from 'config/config';
import useWhiteLabelStyles, { WHITE_LABEL_TYPE_ENUM } from 'hooks/useWhiteLabelStyles';
import HotListHeader from './HotListReport/components/HotListHeader';
import DivideIcon from './HotListReport/components/divideIcon';
import NoDetailsNotice from './HotListReport/components/NoDetailsNotice';
import { CasinoService, IRequestCasinoParams } from 'services/casino';
import { take, tap, Subscription } from 'rxjs';
import { FilterTypeEnum, IFilterType, MapTypeEnum, OrderByEnum } from 'common/common';
import useFetchGeoJson from 'common/reactHooks/useFetchGeoJson';
import { ReduxCommandCreator } from 'appRedux/actions';
import useSlotDetailHistory from 'globalServices/useSlotDetailHistory';
import { FilterReduxCommandCreator } from 'appRedux/actions/filterCommandCreator';
import { FloormapReduxCommandCreator } from 'appRedux/actions/floormapCommandCreator';
import SVGComponent from 'components/SmartLoading/SVGComponent';
import CompareView from './HotListReport/components/CompareView';
import { SlotCompareReduxCommandCreator } from 'appRedux/actions/slotCompareCommandCreator';

const MapStats: React.FC = () => {
  const history = useHistory();
  const casinoSvc = new CasinoService();
  const itemsPerPage = 50;
  const tabSelected = FilterTypeEnum.hottestSlots;
  const listType = 'standard';
  const ionInfiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);
  const styles = useWhiteLabelStyles(WHITE_LABEL_TYPE_ENUM.CANVAS);
  const [presentToast] = useIonToast();
  const dispatch = useDispatch();
  const filterCommandCreator = FilterReduxCommandCreator(dispatch);
  const slotCompareCommands = SlotCompareReduxCommandCreator(dispatch);
  const {
    selectedSlots: selectedToCompare,
    compareSlots,
    isComparing,
  } = useAppSelector((state) => state.app.persistedState.compareSlotsState);
  const commands = ReduxCommandCreator(dispatch);
  const selectedSlots = useSelector<IState>(
    (state) => state.app.persistedState.slotState.selectedSlots
  ) as string[];
  const tabFilters = useSelector<IState>(
    (state) => state.app.persistedState.filterState[tabSelected as IFilterType]
  ) as ITabProperties;
  const router = useIonRouter();
  const casinoSchema: ICasinoSchema = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema
  ) as ICasinoSchema;
  const firstRenderRef = useRef(true);

  const {
    timeframe: tabSelectedTimeframe,
    insight: tabSelectedInsight,
    advancedViewInsights,
  } = tabFilters;

  const fieldSelectorValues = advancedViewInsights?.map((insight: string) => ({
    value: insight,
    label: insight,
  }));
  const { addToSlotHistory, activeSlotNumber } = useSlotDetailHistory();

  const isLandscape = useSelector<IState>(
    (state) => state.app.persistedState.orientationState?.isLandscape
  ) as boolean;

  const [activeInsightFilter, setActiveInsightFilter] = useState<null | InsightValue>(
    tabSelectedInsight
  );
  const [isSplitPaneOpen, setIsSplitPaneOpen] = useState(true);
  const [playData, setPlayData] = useState<ICasinoReport[]>();
  const [hotDataList, setHotDataList] = useState<ICasinoReport[]>([]);
  const [totalReportCount, setTotalReportCount] = useState<number>(0);
  const [isEndReached, setIsEndReached] = useState<boolean>(false);
  const [isCasinoLoading, setCasinoLoading] = useState(false);
  const [isSlotPlayDataIsLoading, setSlotPlayDataIsLoading] = useState(false);
  const { geoJsonData, getGeoJsonLoading, slotMetaData, slotMetaDataLoading } = useFetchGeoJson(
    casinoSchema?.kpCasinoPk
  );
  const floormapCommand = FloormapReduxCommandCreator(dispatch);
  const isTopTenSlot = useSelector<IState>(
    (state) => state.app.persistedState.isSlotOrderAscending
  ) as boolean;
  const [casinoParams, setCasinoParams] = useState<IRequestCasinoParams>({
    casinofk: casinoSchema?.kpCasinoPk,
    timeframe: 6,
    insight: 'spins',
    slot: 0,
    slotnumbers: '',
    limit: itemsPerPage,
    pageNumber: 0,
    orderby: isTopTenSlot ? OrderByEnum.Desc : OrderByEnum.Asc,
  });
  const handleInputChange = (param: keyof IRequestCasinoParams, value: string | number) => {
    setCasinoParams((prevParams) => {
      if (param === 'pageNumber' && value === 0) {
        const newOrderBy = isTopTenSlot ? OrderByEnum.Desc : OrderByEnum.Asc;
        return { ...prevParams, [param]: value, orderby: newOrderBy };
      } else {
        return { ...prevParams, [param]: value };
      }
    });
  };

  const handleFetchMore = (): void => {
    handleInputChange('pageNumber', casinoParams.pageNumber + 1);
  };

  const fetchPlayDataReport = (queryParams: IRequestCasinoParams): Subscription => {
    setSlotPlayDataIsLoading(true);
    const { limit, pageNumber, ...restParam } = queryParams;

    return casinoSvc.GetPlayDataReport(restParam).subscribe({
      next(casinoResponse) {
        if (casinoResponse) {
          setPlayData(casinoResponse.DataSet);
        }
      },
      error(_err) {
        setSlotPlayDataIsLoading(false);
        presentToast({
          position: 'top',
          duration: 5000,
          color: 'danger',
          message: 'Failed to retrieve casino report.',
        }).catch(() => null);
        ionInfiniteScrollRef.current?.complete();
      },
      complete() {
        setSlotPlayDataIsLoading(false);
      },
    });
  };

  const fetchListReport = (queryParams: IRequestCasinoParams): Subscription => {
    return casinoSvc.ListReport(queryParams).subscribe({
      next(casinoResponse) {
        if (casinoResponse) {
          if (queryParams.pageNumber === 0) {
            setHotDataList(casinoResponse.DataSet);
          } else {
            setHotDataList((prevItems) => [...prevItems, ...casinoResponse.DataSet]);
          }
          if (casinoResponse.DataSet && casinoResponse.DataSet.length < itemsPerPage) {
            setIsEndReached(true);
          }
        }
        ionInfiniteScrollRef.current?.complete();
      },
      error(_err) {
        setCasinoLoading(false);
        presentToast({
          position: 'top',
          duration: 5000,
          color: 'danger',
          message: 'Failed to retrieve casino report.',
        }).catch(() => null);
        ionInfiniteScrollRef.current?.complete();
      },
      complete() {
        setCasinoLoading(false);
      },
    });
  };

  const fetchTotalReportCount = (queryParams: IRequestCasinoParams): Subscription => {
    return casinoSvc
      .CountListReport(
        queryParams.casinofk,
        queryParams.timeframe,
        queryParams.slot,
        queryParams.slotnumbers
      )
      .pipe(
        take(1),
        tap((data) => {
          if (data && data.DataSet) {
            setTotalReportCount(data.DataSet[0].total_count);
          }
        })
      )
      .subscribe();
  };

  const memoizedCasinoParams = useMemo(() => {
    if (casinoParams.casinofk) {
      return casinoParams;
    } else {
      history.push('/auth/choosecasinos');
      return casinoParams;
    }
  }, [
    casinoParams.casinofk,
    casinoParams.insight,
    casinoParams.timeframe,
    casinoParams.slot,
    casinoParams.slotnumbers,
    casinoParams.orderby,
    casinoParams.pageNumber,
  ]);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      const subscription = fetchTotalReportCount(memoizedCasinoParams);
      return () => {
        subscription.unsubscribe();
      };
    }
  }, []);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      const listReportSubscription = fetchListReport(memoizedCasinoParams);
      return () => {
        listReportSubscription.unsubscribe();
      };
    }
  }, [memoizedCasinoParams]);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      const playDataReportSubscription = fetchPlayDataReport(memoizedCasinoParams);
      return () => {
        playDataReportSubscription.unsubscribe();
      };
    }
  }, [
    memoizedCasinoParams.casinofk,
    memoizedCasinoParams.insight,
    memoizedCasinoParams.timeframe,
    memoizedCasinoParams.slot,
    memoizedCasinoParams.slotnumbers,
    memoizedCasinoParams.orderby,
  ]);

  useEffect(() => {
    handleInputChange('insight', tabSelectedInsight as InsightValue);
    if (selectedSlots.length > 0) {
      handleInputChange('slotnumbers', selectedSlots ? selectedSlots.join(',') : '');
    }
    setActiveInsightFilter(tabSelectedInsight as InsightValue);
    handleInputChange('pageNumber', 0);
  }, [selectedSlots]);

  useEffect(() => {
    handleInputChange('insight', tabSelectedInsight as InsightValue);
    if (isComparing) {
      handleInputChange('slotnumbers', compareSlots ? compareSlots.join(',') : '');
    }
    setActiveInsightFilter(tabSelectedInsight as InsightValue);
    handleInputChange('pageNumber', 0);
  }, [compareSlots]);
  useEffect(() => {
    handleInputChange('insight', tabSelectedInsight as InsightValue);
    handleInputChange('casinofk', casinoSchema?.kpCasinoPk);
    setActiveInsightFilter(tabSelectedInsight as InsightValue);
    handleInputChange('pageNumber', 0);
  }, [casinoSchema?.kpCasinoPk]);

  useEffect(() => {
    handleInputChange('insight', tabSelectedInsight as InsightValue);
    setActiveInsightFilter(tabSelectedInsight as InsightValue);
    handleInputChange('pageNumber', 0);
  }, [tabSelectedInsight]);

  useEffect(() => {
    const filterTime = config.allTimeFrames.filter(
      (timeframe) => timeframe.value === tabSelectedTimeframe
    )[0];
    handleInputChange('timeframe', filterTime.id);
    handleInputChange('pageNumber', 0);
  }, [tabSelectedTimeframe]);

  useEffect(() => {
    let isUnmounted = false;
    if (!isUnmounted) {
      if (isLandscape) {
        setIsSplitPaneOpen(false);
      } else {
        setIsSplitPaneOpen(true);
      }
    }
    return () => {
      isUnmounted = true;
    };
  }, [isLandscape]);

  const getSortIcon = (column: string): JSX.Element | null => {
    if (column === casinoParams.insight) {
      return casinoParams.orderby === OrderByEnum.Asc ? (
        <FontAwesomeIcon icon={faCaretUp} className="sort-arrow-insights insight-name-sort-icon" />
      ) : (
        <FontAwesomeIcon
          icon={faCaretDown}
          className="sort-arrow-insights insight-name-sort-icon"
        />
      );
    }
    return null;
  };

  const handleSortChange = (column: string) => {
    if (column != 'game_name') {
      const insightWasChanged = tabSelectedInsight !== column;
      if (insightWasChanged) {
        filterCommandCreator.SetFilterOptions(tabSelected, {
          insight: column as InsightValue,
        });
      }
    }
    setCasinoLoading(true);

    setCasinoParams((prevParams) => {
      const newOrderby =
        prevParams.orderby === OrderByEnum.Asc ? OrderByEnum.Desc : OrderByEnum.Asc;
      const updatedParams = {
        ...prevParams,
        pageNumber: 0,
        insight: column,
        orderby: newOrderby,
      };
      commands.SetSlotOrderState(newOrderby === OrderByEnum.Desc);
      return updatedParams;
    });
  };
  const isLoading =
    isSlotPlayDataIsLoading || isCasinoLoading || getGeoJsonLoading || slotMetaDataLoading;

  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;
  return (
    <div className="mapstats">
      <DropdownBar
        id={tabSelected}
        fieldSelectorValues={fieldSelectorValues}
        onClearSearch={() => {
          handleInputChange('slotnumbers', '');
        }}
      />

      <div
        id="slotList"
        className={clsx('box box-list', isSplitPaneOpen ? 'box-open' : 'box-close')}
      >
        <IonContent className="list-report-content">
          {hotDataList &&
            hotDataList.length === 0 &&
            !isCasinoLoading &&
            !isSlotPlayDataIsLoading &&
            listType === 'standard' && (
              <NoDetailsNotice selectedTimeFrameValue={tabSelectedTimeframe as string} />
            )}
          {selectedToCompare.length > 0 && (
            <CompareView
              selectedSlots={selectedToCompare}
              isCompare={isComparing}
              onCompareSlot={() => {
                slotCompareCommands.startComparing();
              }}
              clearComparedSlots={() => {
                handleInputChange('slotnumbers', '');
                slotCompareCommands.clearSlots();
              }}
            />
          )}
          {!isCasinoLoading &&
            !isSlotPlayDataIsLoading &&
            hotDataList &&
            hotDataList.length > 0 && (
              <>
                <IonRow className="slot-row sticky-top">
                  <IonCol size={isLandscape ? '3' : '7'} className="slot-name-label">
                    <div className="insight-header-title">
                      Game Name
                      {getSortIcon('game_name')}
                    </div>
                  </IonCol>

                  <HotListHeader
                    isAdvancedDisplayState={isProUser ? isLandscape : false}
                    totalCount={totalReportCount.toString()}
                    onSelect={(rowData) => {
                      handleSortChange(rowData.insight);
                    }}
                    selectedInsight={tabSelectedInsight as InsightValue}
                    sortOrder={casinoParams.insight}
                    sortBy={casinoParams.orderby}
                    advancedViewInsights={advancedViewInsights as InsightValue[]}
                    activeInsightFilter={activeInsightFilter}
                    listType={listType}
                  />
                </IonRow>

                {hotDataList && (
                  <HotListReport
                    slotData={playData}
                    hotlist_detail={hotDataList}
                    insightKey={tabSelectedInsight as InsightValue}
                    listType={listType}
                    advancedViewInsights={advancedViewInsights}
                    isAdvancedDisplayState={isProUser ? isLandscape : false}
                    onClickSlot={(data) => {
                      addToSlotHistory(parseInt(data?.slot_number));
                      router.push('/detail');
                    }}
                  />
                )}

                <IonInfiniteScroll
                  threshold="100px"
                  disabled={isEndReached}
                  ref={ionInfiniteScrollRef}
                  onIonInfinite={handleFetchMore}
                >
                  <IonInfiniteScrollContent
                    loadingSpinner="bubbles"
                    loadingText="Loading more data..."
                  />
                </IonInfiniteScroll>
              </>
            )}
        </IonContent>
      </div>
      {!isLandscape && (
        <div
          className={clsx('divide', !isSplitPaneOpen ? 'expand' : '')}
          style={styles}
          onClick={() => {
            setIsSplitPaneOpen(!isSplitPaneOpen);
          }}
        >
          <DivideIcon color={casinoSchema?.styles.color} isOpen={isSplitPaneOpen} />
        </div>
      )}
      {isSplitPaneOpen && (
        <div className="box">
          {!getGeoJsonLoading && !isSlotPlayDataIsLoading && (
            <FloorMap
              mapId={MapTypeEnum.slotReportMap}
              slotData={playData}
              slotDataIsLoading={isLoading}
              sortDirection={casinoParams.orderby}
              previousSelectedSlotNumber={activeSlotNumber}
              shouldHideSlotMark={true}
              selectedInsight={tabSelectedInsight as InsightValue}
              selectedTimeFrame={tabSelectedTimeframe}
              isShowData={true}
              selectedSlots={isComparing ? compareSlots : selectedSlots}
              geoJsonData={geoJsonData}
              getGeoJsonLoading={getGeoJsonLoading}
              slotMetaData={slotMetaData}
              onClickHeatFilter={(selectedValue: number) => {
                floormapCommand.SetHeatFilterAction(selectedValue);
              }}
              onClickSlot={(data) => {
                addToSlotHistory(parseInt(data?.slot_number));
                router.push('/detail');
              }}
            />
          )}
        </div>
      )}
      {isLoading && (
        <div className="loader-container">
          <div style={{ height: '100%', textAlign: 'center' }}>
            <SVGComponent message="Wait for it" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MapStats;
