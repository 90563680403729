/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow, useIonToast } from '@ionic/react';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
import { compareDesc, parseISO } from 'date-fns';

import { AuthService, ICasinoAccess, ICasinoLinkedAccount } from 'services/auth';
import { CasinoService } from 'services/casino';
import { FilterTypeEnum, checkProUser, heatLegendAllInsight } from 'common/common';

import { ICasinoSchema, ICasinoSchemaWithAccess } from 'appRedux/models/casinoModels';
import { ReduxCommandCreator } from 'appRedux/actions';
import { AUTH_STATE } from 'appRedux/types/reduxTypes';
import { IFilterState, IUserInfo, useAppSelector } from 'appRedux/createStore';
import { CasinoReduxCommandCreator } from 'appRedux/actions/casinoCommandCreator';
import { FilterReduxCommandCreator } from 'appRedux/actions/filterCommandCreator';
import { FloormapReduxCommandCreator } from 'appRedux/actions/floormapCommandCreator';
import { IUserSubscription } from '@interfaces/userSubscription';

import SmartLoading from 'components/SmartLoading';
import { CasinoCard } from 'components/CasinoCard.tsx/CasinoCard';
import { lastValueFrom } from 'rxjs';
import CasinoNavBar from 'components/CasinoNavBar';
import { SlotReduxCommandCreator } from 'appRedux/actions/slotCommandCreator';

const slotCheckCasinoId = parseInt(__SLOTCHECK_ID__);

export const ChooseCasinos: FC = () => {
  const isMounted = true;
  const casinoSvc = new CasinoService();
  const auth = new AuthService();
  const history = useHistory();
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);
  const casinoCommands = CasinoReduxCommandCreator(dispatch);
  const slotCommands = SlotReduxCommandCreator(dispatch);
  const floormapCommand = FloormapReduxCommandCreator(dispatch);

  const { aliasName, isProUser } = useAppSelector(
    (state) => state.app.persistedState.userInfo
  ) as IUserInfo;
  const casinoLinkedAccounts = useAppSelector(
    (state) => state.app.persistedState.linkPlayerState?.casinoLinkedAccounts
  ) as ICasinoLinkedAccount[];

  const [presentToast] = useIonToast();

  const [isLoading, setIsLoading] = useState(false);
  const filterCommandCreator = FilterReduxCommandCreator(dispatch);

  const [casinos, setCasinos] = useState<ICasinoSchemaWithAccess[]>([]);
  const [recentCasinos, setRecentCasinos] = useState<ICasinoSchemaWithAccess[]>([]);
  const [otherCasinos, setOtherCasinos] = useState<ICasinoSchemaWithAccess[]>([]);

  const primaryCasinos = useAppSelector(
    (state) => state.app.persistedState.userInfo?.primaryCasino || []
  );

  const [comingSoonCasinos, setComingSoonCasinos] = useState<ICasinoSchema[]>([]);

  const addAccessInfoToCasino = (
    casinoList: ICasinoSchema[],
    casinoAccess: ICasinoAccess
  ): ICasinoSchemaWithAccess[] => {
    return casinoList.flatMap((casinoInfo) => {
      if (!casinoInfo) {
        return [];
      }

      const casinoId = casinoInfo.casinoId;
      // eslint-disable-next-line security/detect-object-injection
      const casinoAccessInfo = casinoAccess[casinoId];

      return [
        {
          ...casinoInfo,
          hasAccess: casinoAccessInfo?.hasAccess || false,
          timeLeft: casinoAccessInfo?.timeLeft || 0,
        },
      ];
    });
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);

      try {
        slotCommands.ClearSelectedSlots();
        const casinoResponse = await lastValueFrom(casinoSvc.GetAllCasinos());

        if (casinoResponse && isMounted) {
          const filteredCasinos = casinoResponse.DataSet.filter(
            (casinoInfo) => casinoInfo.casinoId !== slotCheckCasinoId
          );

          const activeCasinos = filteredCasinos.filter(
            (casinoInfo) => casinoInfo.isactive && !casinoInfo.isComingSoon
          );

          const comingSoonCasinos = filteredCasinos.filter(
            (casinoInfo) => casinoInfo.isactive && casinoInfo.isComingSoon
          );

          setComingSoonCasinos(comingSoonCasinos);

          const activeCasinoIds = [...activeCasinos, ...comingSoonCasinos].map(
            (casinoInfo) => casinoInfo.casinoId
          );

          // Fetch user info for active casinos
          if (activeCasinoIds && activeCasinoIds.length > 0) {
            const userInfo = await lastValueFrom(auth.GetUserInfo(activeCasinoIds));

            if (userInfo && isMounted) {
              const currentPeriodEnd =
                userInfo.user.subscription?.subscriptionData.current_period_end;
              const subscriptionActive = userInfo.user.subscription?.subscriptionData.status;
              const isProUser = checkProUser(subscriptionActive, currentPeriodEnd);

              commands.SetUserLevel({
                isProUser: isProUser,
                aliasInitial: userInfo.user.info.aliasInitial,
                aliasInitial2: userInfo.user.info.aliasInitial2,
                aliasName: userInfo.user.info.aliasName,
              });
              casinoCommands.SetLinkedCasinos({
                casinoLinkedAccounts: userInfo.user.linkedAccounts,
              });

              const {
                primaryCasino = null,
                recentCasinos = [],
                otherCasinos = [],
              } = userInfo.user.userCasinoList || {};

              const { casinoAccess } = userInfo.user;

              if (recentCasinos) {
                let recentCasinoList = recentCasinos;

                if (primaryCasino?.casinoId) {
                  recentCasinoList = recentCasinos.filter(
                    (casino) => casino.casinoId !== primaryCasino.casinoId
                  );
                }

                const sortedCasinos = recentCasinoList.sort((a, b) => {
                  if (a?.lastVisit && b?.lastVisit) {
                    return compareDesc(parseISO(a.lastVisit), parseISO(b.lastVisit));
                  }

                  return 0;
                });

                recentCasinos &&
                  setRecentCasinos(addAccessInfoToCasino(sortedCasinos, casinoAccess));
              }

              if (activeCasinos) {
                setCasinos(addAccessInfoToCasino(activeCasinos, casinoAccess));
              }

              if (primaryCasino) {
                const primaryCasinoWithAccess = addAccessInfoToCasino(
                  [primaryCasino],
                  casinoAccess
                );
                commands.SetUserPrimaryCasino(primaryCasinoWithAccess);
              }

              if (otherCasinos) {
                const otherWithoutIsComingSoon = otherCasinos.filter(
                  (casinoInfo) => casinoInfo.isactive && !casinoInfo.isComingSoon
                );

                otherCasinos &&
                  setOtherCasinos(addAccessInfoToCasino(otherWithoutIsComingSoon, casinoAccess));
              }
            }
          }
        }
      } catch (error) {
        if (isMounted) {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to retrieve casino information.',
          }).catch(() => null);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData().catch((error) => {
      console.log(error);
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const updateUserPreference = (reqParam: IFilterState[] | undefined) => {
    if (reqParam === undefined) {
      filterCommandCreator.ResetAllFilters();
    } else {
      const filters = reqParam[0];

      if (filters && FilterTypeEnum && FilterTypeEnum.hottestSlots in filters) {
        filterCommandCreator.SetFilterOptions(FilterTypeEnum.hottestSlots, {
          ...filters[FilterTypeEnum.hottestSlots],
        });
      }

      if (filters && FilterTypeEnum && FilterTypeEnum.leaderboard in filters) {
        filterCommandCreator.SetFilterOptions(FilterTypeEnum.leaderboard, {
          ...filters[FilterTypeEnum.leaderboard],
        });
      }
      if (filters && FilterTypeEnum && FilterTypeEnum.jackpot in filters) {
        filterCommandCreator.SetFilterOptions(FilterTypeEnum.jackpot, {
          ...filters[FilterTypeEnum.jackpot],
        });
      }
      if (filters && FilterTypeEnum && FilterTypeEnum.myplay in filters) {
        filterCommandCreator.SetFilterOptions(FilterTypeEnum.myplay, {
          ...filters[FilterTypeEnum.myplay],
        });
      }
    }
  };

  function findCasinoSubscription(
    casinoId: number,
    subscriptions: IUserSubscription[]
  ): IUserSubscription | null {
    const subscription = subscriptions.find(
      (subscription) =>
        subscription && typeof subscription === 'object' && subscription.casinoId === casinoId
    );
    return subscription || null;
  }

  const getUserSubscriptionAndPreferences = (
    subscriptions: IUserSubscription[],
    preference: IFilterState[] | undefined,
    appUserPk: string
  ) => {
    const subscription = findCasinoSubscription(slotCheckCasinoId, subscriptions);

    if (subscription) {
      const { subscriptionData, customerData } = subscription;
      const customerId = customerData?.id;
      const planId = subscriptionData?.plan?.id;
      const subscriptionId = subscriptionData?.id;
      const currentPeriodEnd = subscriptionData?.current_period_end;
      const canceledAt = subscriptionData?.canceled_at;
      const subscriptionActive = subscriptionData?.status;
      const isProUser = checkProUser(subscriptionActive, currentPeriodEnd);

      if (isProUser) {
        preference && updateUserPreference(preference);
      } else {
        updateUserPreference(undefined);
      }

      commands.SetUserLevel({
        level: planId,
        customer_id: customerId,
        subscription_id: subscriptionId,
        current_period_end: currentPeriodEnd,
        canceled_at: canceledAt,
        isProUser,
        appUserPk: appUserPk,
      });
    } else {
      updateUserPreference(undefined);
      commands.SetUserLevel({
        level: '',
        customer_id: '',
        subscription_id: '',
        isProUser: false,
      });
    }
  };

  const onClickCasino = (casinoDetail: ICasinoSchema) => {
    if (casinoDetail.isComingSoon) {
      return;
    }

    if (isMounted) {
      setIsLoading(true);
    }

    const { casinoTimezoneNew, casinoId } = casinoDetail;
    casinoCommands.SetCasinoSchema(casinoDetail);
    moment.tz.setDefault(casinoTimezoneNew);
    floormapCommand.SetDefaultHeatFilterAction(heatLegendAllInsight);

    auth.GetMe(casinoId).subscribe({
      next(data) {
        if (data?.user) {
          const { preference, linkedAccounts, hasAccess } = data.user;
          const {
            aliasInitial2,
            aliasInitial,
            aliasName,
            firstName,
            lastName,
            emailAddress,
            appUserPk: appUserFk,
          } = data.user.info;

          const aliasInformation: Partial<IUserInfo> = {
            aliasInitial: aliasInitial,
            aliasInitial2: aliasInitial2,
            aliasName: aliasName,
          };

          commands.SetTourState(data.user.info?.hasCompletedOnboarding as boolean);
          commands.SetUserPrivacy(data.user.info.isPublicVisible);
          commands.SetUserAlias({
            ...aliasInformation,
          });
          const userInformation: Partial<IUserInfo> = {
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
          };
          commands.SetUserInformation({
            ...userInformation,
          });

          if (data.user.subscriptions) {
            getUserSubscriptionAndPreferences(
              data.user.subscriptions,
              preference as IFilterState[],
              data.user.info?.appUserPk
            );
          } else {
            commands.SetUserLevel({
              level: '',
              customer_id: '',
              subscription_id: '',
              isProUser: false,
            });
          }

          if (!hasAccess) {
            history.push(`/auth/link-player/${casinoId}`);
            return;
          }

          if (linkedAccounts && linkedAccounts.length > 0) {
            const casinoItem = linkedAccounts.find(
              (item: ICasinoLinkedAccount) => item.casinoId === casinoId
            );
            const isPlayerLinked = Boolean(casinoItem);
            const linkedCasinoId = casinoItem?.casinoId || null;

            casinoCommands.SetLinkedCasinos({
              casinoLinkedAccounts: linkedAccounts,
              isPlayerLinked,
              linkedCasinoId,
            });

            if (isPlayerLinked) {
              history.push('/tabs/home');
            } else {
              history.push(`/auth/link-player/${casinoId}`);
            }
          } else {
            casinoCommands.SetLinkedCasinos({
              casinoLinkedAccounts: [],
              isPlayerLinked: false,
              linkedCasinoId: null,
            });

            history.push(`/auth/link-player/${casinoId}`);
          }
          casinoSvc.UpdateRecentCasinoID(String(casinoId), appUserFk).subscribe();
        }
      },
      error(err) {
        commands.SetUserInfo('', '');
        commands.SetAuthState(AUTH_STATE.SIGN_IN);
      },
      complete() {
        if (isMounted) {
          setIsLoading(false);
        }
      },
    });
  };

  const renderTitleContainer = (title: string) => {
    return (
      <div className="title-container">
        <div role="heading" aria-level={2} className="title">
          {title}
        </div>
        {title === 'Primary' && (
          <a href="/auth/edit-primary-casino" className="edit-link body-small-bold">
            Edit
          </a>
        )}
      </div>
    );
  };

  const renderCasinoItem = (casino: ICasinoSchemaWithAccess, key: string) => {
    const { displayName, casinoId, logos, website, address, isComingSoon, timeLeft, hasAccess } =
      casino;

    let isPlayerLinked = false;
    if (casinoLinkedAccounts) {
      isPlayerLinked = casinoLinkedAccounts.some((account) => account.casinoId === casinoId);
    }

    return (
      <IonRow data-testid="choose-casino-item" key={key}>
        <IonCol size="12">
          <CasinoCard
            key={casinoId?.toString()}
            actionText="Go to casino"
            logoIcon={logos.navBar}
            name={displayName}
            website={website}
            address={address.displayAddress}
            onActionClick={() => onClickCasino(casino)}
            isComingSoon={isComingSoon}
            isPlayerLinked={isPlayerLinked}
            hasAccess={hasAccess}
            timeLeft={timeLeft}
          />
        </IonCol>
      </IonRow>
    );
  };

  const onUpgradeClick = () => {
    history.push('/auth/stripe', {
      previousPage: '/auth/choosecasinos',
    });
  };

  return (
    <IonPage className="app-parent">
      <IonContent>
        <SmartLoading loading={isLoading} />
        {!isLoading && (
          <div className="container">
            <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr', height: '100vh' }}>
              <div>
                <CasinoNavBar
                  hideSearch
                  hideNotify
                  hideLogo
                  styles={{ backgroundColor: 'rgb(42, 116, 162)' }}
                />
              </div>
              <IonGrid className="ion-no-margin m-signup m-signup__choose-casino">
                <div className="logo">
                  <IonImg src="assets/images/SLOTcheckTM.svg" />
                </div>

                <div className="user-alias subtitle1">Welcome {aliasName}</div>

                {!isProUser && (
                  <div className="upgrade-card">
                    <div className="title body">
                      <span>
                        Currently on <span className="body-bold">Free</span> version
                      </span>

                      <a className="link" href="javascript:void(0)" onClick={onUpgradeClick}>
                        Upgrade
                      </a>
                    </div>

                    <div className="body description">
                      For continued access, Player ID for each casino is required 72-hours after
                      initial access.
                    </div>
                  </div>
                )}

                <div className="choose-casinos my-casinos">
                  <IonRow>
                    <IonCol>
                      <div className="header-bar">
                        <h1 className="heading5 heading">Choose a Casino</h1>
                      </div>
                    </IonCol>
                  </IonRow>

                  {primaryCasinos?.length === 0 &&
                    recentCasinos?.length === 0 &&
                    (casinos || []).map((eachCasino, index) => {
                      return renderCasinoItem(eachCasino, `casino-key-${index}`);
                    })}

                  {primaryCasinos.length > 0 && (
                    <>
                      {renderTitleContainer('Primary')}
                      {(primaryCasinos || []).map((eachCasino, index) => {
                        return renderCasinoItem(eachCasino, `casino-key-${index}`);
                      })}
                    </>
                  )}

                  {recentCasinos.length > 0 && (
                    <>
                      {renderTitleContainer('Recents')}
                      {(recentCasinos || []).map((eachCasino, index) => {
                        return renderCasinoItem(eachCasino, `casino-key-${index}`);
                      })}
                    </>
                  )}

                  {otherCasinos.length > 0 && (
                    <>
                      {renderTitleContainer('Visit other casinos')}
                      {otherCasinos.map((eachCasino, index) => {
                        return renderCasinoItem(eachCasino, `casino-key-${index}`);
                      })}
                    </>
                  )}

                  {comingSoonCasinos.length >= 1 && (
                    <>
                      {renderTitleContainer('Coming Soon')}
                      {comingSoonCasinos?.map((casino, index) =>
                        renderCasinoItem(casino, `casino-soon-key-${index}`)
                      )}
                    </>
                  )}
                </div>
              </IonGrid>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
