import { slotCompareActionTypes } from 'appRedux/actions/slotCompareCommandCreator';
import { IReducer } from './moduleReducerRegistry';
import { ICompareSlotInfo } from 'appRedux/models/casinoModels';

export const slotCompareInitialState: ICompareSlotInfo = {
  selectedSlots: [],
  compareSlots: [],
  isComparing: false,
};

export const slotCompareReducer: IReducer = (draft, action) => {
  const { type, state } = action;
  switch (type) {
    case slotCompareActionTypes.TOGGLE_SELECTED_SLOT:
      if (draft.app?.persistedState?.compareSlotsState) {
        const { compareSlotsState } = draft.app.persistedState;
        const slotIndex = compareSlotsState.selectedSlots.indexOf(state.slotNumber);
        if (slotIndex > -1) {
          compareSlotsState.selectedSlots.splice(slotIndex, 1);
        } else {
          compareSlotsState.selectedSlots.push(state.slotNumber);
        }
      }
      break;
    case slotCompareActionTypes.START_COMPARING:
      if (draft.app?.persistedState?.compareSlotsState) {
        const { compareSlotsState } = draft.app.persistedState;
        compareSlotsState.compareSlots = [...compareSlotsState.selectedSlots]; // Copy selected slots to compare
        compareSlotsState.isComparing = true;
      }
      break;

    case slotCompareActionTypes.STOP_COMPARING:
      if (draft.app?.persistedState?.compareSlotsState) {
        const { compareSlotsState } = draft.app.persistedState;
        compareSlotsState.isComparing = false;
        compareSlotsState.compareSlots = [];
      }
      break;

    case slotCompareActionTypes.CLEAR_SLOTS:
      if (draft.app?.persistedState?.compareSlotsState) {
        const { compareSlotsState } = draft.app.persistedState;
        compareSlotsState.selectedSlots = [];
        compareSlotsState.compareSlots = [];
        compareSlotsState.isComparing = false;
      }
      break;
    default:
      break;
  }
};

export default slotCompareReducer;
