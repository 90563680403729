import React, { useEffect, useRef, useMemo } from 'react';
import Chart from 'chart.js/auto';
import moment from 'moment-timezone';
import { getNearestTimeFrame } from '../../common/common';
import { parseValueGraph } from '../../common/include';
import { config, HeatBeanObjectKey } from '../../config/config';
import { chartOptions } from '../chart/chart-options';
import { CombinedGraphData, ISlotGraphData } from 'appRedux/models/casinoModels';

const { heatBeanRatings } = config;
interface ContextType {
  raw: {
    bucket: string;
  };
}

interface ChartOptionsParams {
  timeframe: string;
  insight: string;
  backgroundColor: string;
  hasNoJackpotsToDisplay: boolean;
}

interface BarChartComponentProps {
  slotData: CombinedGraphData[];
  insight: string;
  timeframe: string;
  backgroundColor: string;
  hasNoJackpotsToDisplay: boolean;
}

const isSlotGraphData = (datum: CombinedGraphData): datum is ISlotGraphData => {
  return (datum as ISlotGraphData).bucket !== undefined;
};

const processDataForChart = (slotData: CombinedGraphData[], insight: string) => {
  const insightKey = insight;
  const chartData = { datasets: [] as any[] };
  const chartBarColorByDayMap: { [key: string]: { heatColorId: number } } = {};
  slotData.forEach((datum) => {
    if (isSlotGraphData(datum)) {
      const bucket = moment(datum.bucket).format();
      chartBarColorByDayMap[bucket] = chartBarColorByDayMap[bucket] || { heatColorId: 0 };
      chartBarColorByDayMap[bucket].heatColorId = Math.max(
        parseInt(datum[`${insightKey}_ntile`] as string, 10) || 0,
        chartBarColorByDayMap[bucket].heatColorId
      );
    }
  });

  const processedSlotData =
    slotData &&
    slotData.map((datum) => {
      if (isSlotGraphData(datum)) {
        return {
          ...datum,
          [insightKey]:
            insightKey === 'jackpot_value'
              ? datum.hasOwnProperty('insight_value')
                ? datum.insight_value
                : datum[insightKey]
              : datum[insightKey] || 0,
          bucket: moment(datum.bucket).format(),
          time: moment(datum.bucket).format('hh:mm a'),
        };
      }
      return datum;
    });

  const dataset = {
    label: insight,
    type: 'bar',
    order: 3,
    data: processedSlotData,
    parsing: {
      yAxisKey: insightKey,
      xAxisKey: 'bucket',
    },
    fill: false,
    tension: 0.1,
    backgroundColor: (context: ContextType) => {
      const date = context.raw?.bucket;
      if (date) {
        const { heatColorId = 0 } = chartBarColorByDayMap[date];
        return heatBeanRatings[heatColorId as HeatBeanObjectKey].color;
      }
    },
    borderColor: '#192136',
    borderWidth: 1,
  };

  chartData.datasets.push(dataset);

  return chartData;
};

const getChartOptions = ({
  timeframe,
  insight,
  backgroundColor,
  hasNoJackpotsToDisplay,
}: ChartOptionsParams) => {
  const customChartOptions = { ...chartOptions };

  const toDate = moment();
  toDate.minutes(Math.floor(toDate.minutes() / 5) * 5);

  const ticksXAxis = {
    offset: true,
    color: 'white',
    maxRotation: 0,
    minRotation: 0,
    callback: function (value: string, index: number) {
      const isTimeframeMonth = timeframe === 'months1';
      const isTimeframeHours = timeframe.includes('hours');
      const date = moment(value)
        .add(timeframe.includes('weeks1') || isTimeframeMonth ? 1 : 0, 'day')
        .format('D');

      const isSpecialTime = ['12 pm', '12 am', '1 am', '1 pm'].includes(value);
      const showFullValue =
        index === 0 || date === '1' || (date === '2' && isTimeframeMonth) || isSpecialTime;

      return showFullValue ? value : isTimeframeHours ? value.split(' ')[0] : date;
    },
  };

  customChartOptions.scales.x.time.minUnit = timeframe.includes('hours') ? 'hour' : 'day';
  customChartOptions.scales.x.ticks = ticksXAxis;
  const yAxisTitle = {
    display: true,
    text: '',
    color: backgroundColor,
    font: { size: 14 },
  };

  const ticksYAxis = {
    color: 'white',
    callback: function (value: string, index: number) {
      if (hasNoJackpotsToDisplay) {
        return [3, 6].includes(index) ? parseValueGraph(index * 1200, insight) : '';
      }
      return parseValueGraph(value, insight);
    },
  };
  customChartOptions.maintainAspectRatio = false;
  customChartOptions.scales.y.title = yAxisTitle;
  customChartOptions.scales.y.ticks = ticksYAxis;

  if (hasNoJackpotsToDisplay) {
    customChartOptions.scales.y.min = 800;
    customChartOptions.scales.y.max = 2800;
    customChartOptions.scales.y.ticks.stepSize = 200;
  } else {
    delete customChartOptions.scales.y.min;
    delete customChartOptions.scales.y.max;
  }

  if (timeframe !== 'sinceLastJackpot') {
    customChartOptions.scales.x.min = moment(getNearestTimeFrame(timeframe)).format();
  } else {
    delete customChartOptions.scales.x.min;
  }

  customChartOptions.scales.x.max = toDate.format();
  if (insight.includes('jackpots')) {
    customChartOptions.scales.y.ticks.stepSize = 1;
  }

  customChartOptions.events = ['click'];

  return customChartOptions;
};

const BarChartComponent: React.FC<BarChartComponentProps> = ({
  slotData,
  insight,
  timeframe,
  backgroundColor,
  hasNoJackpotsToDisplay,
}) => {
  const chartContainer = useRef<HTMLCanvasElement | null>(null);
  const slotChart = useRef<Chart | null>(null);
  const chartBar = useRef<HTMLDivElement | null>(null);

  const chartOptions = useMemo(
    () =>
      getChartOptions({
        backgroundColor,
        timeframe,
        insight,
        hasNoJackpotsToDisplay,
      }),
    [backgroundColor, timeframe, insight, hasNoJackpotsToDisplay]
  );
  const chartData = useMemo(() => processDataForChart(slotData, insight), [slotData, insight]);
  useEffect(() => {
    if (slotChart.current) {
      slotChart.current.destroy();
    }

    if (chartContainer.current) {
      slotChart.current = new Chart(chartContainer.current, {
        type: 'bar',
        data: chartData,
        options: chartOptions,
      });
      chartContainer.current.style.overflowX = 'hidden';
    }

    return () => {
      if (slotChart.current) {
        slotChart.current.destroy();
      }
    };
  }, [chartData, chartOptions]);

  return (
    <div className="chartContainer">
      <div className="chartContainerBody" ref={chartBar}>
        <canvas id="slot-chart" ref={chartContainer}></canvas>
      </div>
    </div>
  );
};

export default BarChartComponent;
