import { ApiService } from '@classes/apiClasses';
import { IResponse } from '@interfaces/services';
import { CustomerData, SubscriptionData } from '@interfaces/userSubscription';
import { Post } from 'lib/axiosWrapper';

export interface ICheckoutResponse {
  id: string;
}

export interface IProcessCheckout {
  customerData: CustomerData;
  subscriptionData: SubscriptionData;
}

export interface IManageCustomerResponse {
  url: string;
}

export interface IGetSubscriptionStatus {
  subscriptionData: SubscriptionData;
}

export class StripeService extends ApiService {
  constructor() {
    super('stripe');
  }

  CreateCheckoutSession(priceId: string, couponCode: string, casinoID: number, isoneTime: boolean) {
    return Post<ICheckoutResponse>(
      `${this.baseRoutePath}/CreateCheckoutSession`,
      { priceId, couponCode, casinoID, isoneTime },
      {},
      true
    );
  }

  ManageSubscriptionSession(customerId: string) {
    return Post<IManageCustomerResponse>(
      `${this.baseRoutePath}/ManageSubscriptionSession`,
      { customerId },
      {},
      true
    );
  }

  AddSubscriptionByAdmin(
    productID: string,
    cognitoUserName: string,
    phoneNumber: string,
    endDate: string,
    casinoId: number
  ) {
    return Post<IManageCustomerResponse>(
      `${this.baseRoutePath}/AddSubscriptionByAdmin`,
      { productID, cognitoUserName, phoneNumber, endDate, casinoId },
      {},
      true
    );
  }

  CancelSubscriptionAdmin(objectID: string) {
    return Post<IManageCustomerResponse>(
      `${this.baseRoutePath}/CancelSubscriptionAdmin`,
      { objectID },
      {},
      true
    );
  }

  CancelSubscriptionSession(customerId: string, subscriptionId: string) {
    return Post<IManageCustomerResponse>(
      `${this.baseRoutePath}/CancelSubscriptionSession`,
      { customerId, subscriptionId },
      {},
      true
    );
  }

  CancelSubscription(customerId: string) {
    return Post<IManageCustomerResponse>(
      `${this.baseRoutePath}/CancelSubscription`,
      { customerId },
      {},
      true
    );
  }

  GetSubscriptionStatus() {
    return Post<IResponse<IGetSubscriptionStatus>>(
      `${this.baseRoutePath}/GetSubscriptionStatus`,
      {},
      {},
      true
    );
  }

  AJVtest() {
    return Post<ICheckoutResponse>(`${this.baseRoutePath}/AJVtest`, {}, {}, true);
  }

  ProcessCheckout(sessionId: string) {
    return Post<IResponse<IProcessCheckout>>(
      `${this.baseRoutePath}/ProcessCheckout`,
      { sessionId },
      {},
      true
    );
  }
}
