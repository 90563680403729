import { IonCol, IonRow } from '@ionic/react';
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { InsightValue } from 'common/types';
import { heatBeanRatings } from 'config/HeatBeanRatings';
import { HeatBeanObjectKey } from 'config/config';
import { parseValue } from 'common/include';
import useFavorites from 'common/reactHooks/useFavorites';
import {
  getTopAndBottom,
  getOrderAndHeatKey,
  setHeatKeyForAllItems,
  OrderByEnum,
  slotHotnessScale,
} from 'common/common';
import { ICasinoReport, ICasinoInsights } from 'appRedux/models/casinoModels';
import { IState, useAppSelector } from 'appRedux/createStore';
import { LikeButton } from 'pages/Favorites/components/LikeButton';
import { selectIsSlotOrderAscending, selectUserId } from 'appRedux/selectors';
import { SlotCompareReduxCommandCreator } from 'appRedux/actions/slotCompareCommandCreator';
export interface IHotListItem {
  area: string;
  denom: string;
  game_name: string;
  jackpot_value: number;
  jackpot_value_ntile: number;
  location: string;
  pop_spins: number;
  section: string;
  slot_number: number;
}
type IHotListDataProps = {
  slotData?: ICasinoReport[];
  hotlist_detail: ICasinoReport[];
  total_count?: any;
  insightKey: InsightValue;
  listType: string;
  onClickSlot: (slotdata: any) => void;
  advancedViewInsights: InsightValue[];
  isAdvancedDisplayState: boolean;
};
const HotListReport: React.FC<IHotListDataProps> = ({
  slotData,
  hotlist_detail,
  insightKey,
  listType,
  onClickSlot,
  advancedViewInsights,
  isAdvancedDisplayState,
}: IHotListDataProps) => {
  const { fetchUserFavorites } = useFavorites({});
  const dispatch = useDispatch();
  const slotCompareCommands = SlotCompareReduxCommandCreator(dispatch);
  const { isComparing } = useAppSelector((state) => state.app.persistedState.compareSlotsState);
  const userId = useSelector(selectUserId);
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;
  const isSlotOrderAscending = useSelector(selectIsSlotOrderAscending);
  const { selectedSlots } = useAppSelector(
    (state) => state.app.persistedState.slotState
  );
  const { selectedSlots: selectedCompareSlots } = useAppSelector(
    (state) => state.app.persistedState.compareSlotsState
  );

  let AllTopAndBottomSlots: ICasinoReport[] = [];

  const hotListRowInsights = isAdvancedDisplayState ? advancedViewInsights : [insightKey];

  useEffect(() => {
    fetchUserFavorites(userId);
  }, [userId]);

  if (slotData) {
    const { top10, bottom10 } = getTopAndBottom(slotData, insightKey);
    if (isSlotOrderAscending) {
      for (let index = 0; index < top10.length; index++) {
        const element = { ...top10[index], ...getOrderAndHeatKey(index, OrderByEnum.Desc) };
        AllTopAndBottomSlots = [...AllTopAndBottomSlots, element];
      }
      if (insightKey === 'jackpots' || insightKey === 'jackpot_value') {
        AllTopAndBottomSlots = setHeatKeyForAllItems(AllTopAndBottomSlots, slotHotnessScale.Six);
      }
    } else {
      for (let index = 0; index < bottom10.length; index++) {
        const reversedIndex = bottom10.length - 1 - index;
        const element = {
          ...bottom10[index],
          ...getOrderAndHeatKey(reversedIndex, OrderByEnum.Asc),
        };
        AllTopAndBottomSlots.push(element);
      }
    }
  }
  if (selectedSlots.length > 0) {
    AllTopAndBottomSlots = [];
  }
  const getHeatBean: any = (dataItem: ICasinoReport, insightKey: ICasinoInsights) => {
    if (hotlist_detail?.length > 0) {
      if (hotlist_detail?.find((x: ICasinoReport) => x.slot_number === dataItem.slot_number)) {
        const stats = dataItem[(insightKey + '_ntile') as keyof ICasinoReport];
        return (
          <div className="box-bean">
            <div
              className="box-indicator"
              style={{ background: heatBeanRatings[stats as HeatBeanObjectKey]?.color }}
            />
          </div>
        );
      }
    }
  };

  const renderCol: any = (rowData: ICasinoReport, insightKey: InsightValue, index: number) => {
    const unparsedValue = ['sleeper_slot_rating', 'volatility_rating'].includes(insightKey)
      ? rowData[`${insightKey}_ntile`]
      : rowData[insightKey];
    const parsedValue = parseValue(unparsedValue, insightKey);

    const isJackpotsInsight = insightKey === 'jackpot_value';

    const foundSlot: ICasinoReport | undefined = AllTopAndBottomSlots?.find(
      (x: ICasinoReport) => x.slot_number === rowData.slot_number
    );
    const order: number | undefined = foundSlot?.order;
    const heatKey: HeatBeanObjectKey = foundSlot?.heatKey as HeatBeanObjectKey;

    return (
      <div
        className={clsx(
          'hot-list-row',
          !isAdvancedDisplayState ? 'list-portrait' : '',
          insightKey === 'jackpot_value' ? 'jkpt-column' : ''
        )}
        key={`list-row-${
          isAdvancedDisplayState ? 'is-advanced-view' : 'is-standard-view'
        }-${insightKey}${rowData.slot_number}-${index}`}
      >
        <div className="game-data-td-report">
          <span className={clsx('row-insight-value')}>
            {parsedValue}

            {isJackpotsInsight && listType !== 'myJackpot' && (
              <span className="jackpot_number"> | {rowData['jackpots']}</span>
            )}
          </span>
        </div>

        <>
          {!isAdvancedDisplayState && (
            <>
              {AllTopAndBottomSlots &&
                AllTopAndBottomSlots.some(
                  (eachSlots: ICasinoReport) => eachSlots.slot_number === rowData?.slot_number
                ) && (
                <div className={clsx('report-marker')}>
                  <div
                    className={clsx('report-number')}
                    style={{
                      background: heatKey && heatBeanRatings[heatKey]?.color,
                    }}
                  >
                    {order}
                  </div>
                </div>
              )}
              {AllTopAndBottomSlots &&
                !AllTopAndBottomSlots.some(
                  (eachSlots: ICasinoReport) => eachSlots.slot_number === rowData?.slot_number
                ) &&
                getHeatBean(rowData, insightKey)}
            </>
          )}
          {isAdvancedDisplayState && getHeatBean(rowData, insightKey)}
        </>
      </div>
    );
  };

  const isSlotSelected = (slotNumber: number): boolean => {
    return selectedCompareSlots.includes(slotNumber);
  };

  return (
    <div className="hotlist-report">
      <div className={'slot-report'}>
        {hotlist_detail.map((eachitem, index) => {
          return (
            <IonRow
              className={clsx(
                'slot-table-row ion-align-items-center',
                index % 2 ? 'even' : 'odd',
                isSlotSelected(eachitem.slot_number)
                  ? 'favorites-container__list__item--active'
                  : ''
              )}
              key={`slot-report-${
                isAdvancedDisplayState ? 'is-advanced-view' : 'is-standard-view'
              }-${index}`}
              onClick={() => {
                if (isProUser && !isComparing) {
                  slotCompareCommands.toggleSelectedSlot({ slotNumber: eachitem.slot_number });
                }
              }}
              id="slotListRow"
            >
              <IonCol size={isAdvancedDisplayState ? '3' : '8'}>
                <div className="slot-info">
                  <div
                    className="slot-info-row"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickSlot(eachitem);
                    }}
                  >
                    <span id="slotName" className="slot-name">
                      {eachitem?.game_name}
                    </span>
                    {eachitem?.slot_number && (
                      <span className="click-target slot-number">
                        {String(eachitem?.slot_number).slice(-2)}
                      </span>
                    )}
                  </div>
                  <span>
                    <LikeButton slotNumber={String(eachitem?.slot_number)} />
                  </span>
                </div>
              </IonCol>
              {hotListRowInsights.map((insight: string) => renderCol(eachitem, insight, index))}
            </IonRow>
          );
        })}
      </div>
    </div>
  );
};

export default HotListReport;
