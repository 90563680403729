import { InsightType, SlotMetricsData } from 'common/common';
import { playerTypeEnum } from 'common/commonEnum';

export enum InsightIdEnum {
  MoneyWon = 1,
  Spins = 2,
  AvgWinPerSpins = 3,
  WinningSpins = 4,
  Jackpots = 5,
  NoOfJackpot = 6,
  MoneyPlayed = 7,
  RTP = 8,
  SLEEPSLOT = 9,
  VOL = 10,
  POP = 11,
}

export const Insights: InsightType[] = [
  {
    id: 11,
    value: 'pop',
    field: 'pop',
    label: 'Payout To Player (POP)',
    short_name: 'POP',
    trendLabel: 'POP',
    hotColdLabel: 'percent paid out to player',
    insightFieldsSelectorLabel: 'POP',
    color: '#aba170',
    backgroundColor: '#988f60',
    enableDarkBg: false,
    className: '',
    description: 'None', // To be added
    display_order: 1,
    insightId: InsightIdEnum.POP,
    icon: '^', // To be added
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    id: 5,
    value: 'spins',
    field: 'spins',
    label: 'Spins',
    short_name: 'Spins',
    trendLabel: 'Spins',
    hotColdLabel: 'total spins',
    insightFieldsSelectorLabel: 'Spins',
    color: '#ff6a4b',
    backgroundColor: '#ff6a4b',
    enableDarkBg: false,
    className: '',
    description: 'Total number of spins',
    display_order: 3,
    insightId: InsightIdEnum.Spins,
    icon: '#',
    playerTypeAccess: [playerTypeEnum.slotCheck, playerTypeEnum.slotCheckPro],
  },
  {
    id: 2,
    value: 'avg_money_won_per_spin',
    field: 'avg_money_won_per_spin',
    label: 'Win per Spin',
    enableDarkBg: true,
    short_name: '$ W/S',
    trendLabel: '$ W/S',
    insightFieldsSelectorLabel: '$ W/S',
    hotColdLabel: 'wins per spin',
    color: '#bf9ed2',
    backgroundColor: '#ac88c1',
    className: '',
    description: 'Average amount of money won per spin',
    display_order: 8,
    insightId: InsightIdEnum.AvgWinPerSpins,
    icon: '$',
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    id: 6,
    value: 'win_rate',
    field: 'win_rate',
    label: 'Winning Spins',
    enableDarkBg: true,
    short_name: '% WS',
    trendLabel: '% WS',
    hotColdLabel: '% winning spins',
    insightFieldsSelectorLabel: '% WS',
    color: '#deb084',
    backgroundColor: '#d8aa7f',
    className: '',
    description: '% of spins that won money',
    display_order: 9,
    insightId: InsightIdEnum.WinningSpins,
    icon: '%',
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    id: 8,
    value: 'jackpot_value',
    field: 'jackpot_value',
    label: 'Jackpots',
    enableDarkBg: true,
    short_name: 'Jackpots',
    trendLabel: '$ JKPT',
    hotColdLabel: 'money paid out in jackpots',
    insightFieldsSelectorLabel: '$ | # JKPT',
    color: '#8fffba',
    backgroundColor: '#68c38a',
    className: '',
    description: 'Total amount of money won from jackpots',
    display_order: 5,
    insightId: InsightIdEnum.Jackpots,
    icon: '$',
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    id: 7,
    value: 'jackpots',
    field: 'jackpots',
    label: '# Jackpots',
    enableDarkBg: true,
    trendLabel: '# JKPT',
    hotColdLabel: 'money paid out in jackpots',
    insightFieldsSelectorLabel: '$ | # JKPT',
    short_name: 'Jackpots',
    color: '#22af6a',
    backgroundColor: '#00a052',
    className: '',
    description: 'Total number of jackpots that occurred',
    display_order: 6,
    insightId: InsightIdEnum.NoOfJackpot,
    icon: '#',
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    id: 3,
    value: 'money_played',
    field: 'money_played',
    label: 'Played',
    enableDarkBg: false,
    short_name: 'Played',
    trendLabel: 'Played',
    hotColdLabel: 'money played',
    insightFieldsSelectorLabel: 'Played',
    color: '#00e953',
    backgroundColor: '#18c355',
    className: '',
    description: 'Total money played',
    display_order: 7,
    insightId: InsightIdEnum.MoneyPlayed,
    icon: '$',
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    id: 1,
    value: 'return',
    field: 'return',
    label: 'Return to Player',
    enableDarkBg: false,
    trendLabel: 'RTP',
    hotColdLabel: 'percent returned to player',
    insightFieldsSelectorLabel: 'RTP',
    short_name: 'RTP',
    color: '#b35fdb',
    backgroundColor: '#be64e7',
    className: '',
    description: '% of money won divided by money played',
    display_order: 2,
    insightId: InsightIdEnum.RTP,
    icon: '%',
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    id: 10,
    value: 'volatility_rating',
    field: 'volatility_rating',
    label: 'Volatility',
    enableDarkBg: false,
    short_name: 'VOL',
    trendLabel: 'VOL',
    hotColdLabel: 'relative volatility',
    highVoltilityLabel: 'Volatility Rating (High)',
    lowVoltilityLabel: 'Volatility Rating (Low)',
    insightFieldsSelectorLabel: 'VOL',
    color: '#aba170',
    backgroundColor: '#988f60',
    className: '',
    description:
      'Relatively high % (or low %) returned to player and relatively low % (or high %) winning spins',
    display_order: 4,
    insightId: InsightIdEnum.VOL,
    icon: '^',
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    id: 4,
    value: 'money_won',
    field: 'money_won',
    label: 'Won',
    enableDarkBg: false,
    short_name: 'Won',
    trendLabel: 'Won',
    hotColdLabel: 'money won',
    insightFieldsSelectorLabel: 'Won',
    color: '#7384ff',
    backgroundColor: '#7384ff',
    className: '',
    description: 'Total amount of money the slot has paid out irrespective of amount paid in',
    display_order: 6,
    insightId: InsightIdEnum.MoneyWon,
    icon: '$',
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
];

export const myJackpotsPageInsights = Insights.map((insightInfo) =>
  insightInfo.value === 'jackpot_value'
    ? { ...insightInfo, playerTypeAccess: [playerTypeEnum.slotCheck, playerTypeEnum.slotCheckPro] }
    : insightInfo
);

export const hotSlotsLabels: any = {
  spins: {
    label: 'Spins',
    secondaryLabel: 'Spins',
    asset_type: 'text',
    text: '',
    icon: '',
    color: '#F34D2B',
    is_show_trophy: true,
  },
  jackpots: {
    label: 'Jackpots',
    secondaryLabel: '# JKPT',
    asset_type: 'text',
    text: '',
    icon: '',
    color: '#FFF',
    is_show_trophy: true,
  },
  jackpot_value: {
    label: 'Jackpots (JKPT)',
    secondaryLabel: '$ JKPT',
    asset_type: 'text',
    text: '',
    icon: '',
    color: '#089E3D',
    is_show_trophy: true,
  },
  money_won: {
    label: 'Won',
    secondaryLabel: 'Won',
    asset_type: 'text',
    text: '',
    icon: '',
    color: '#089E3D',
    is_show_trophy: true,
  },
  money_played: {
    label: 'Played',
    secondaryLabel: 'Played',
    asset_type: 'text',
    text: '',
    icon: '',
    color: '#089E3D',
    is_show_trophy: false,
  },
  win_rate: {
    label: 'Winning Spins (% WS)',
    secondaryLabel: '% WS',
    asset_type: 'text',
    text: '',
    icon: '',
    color: '#DBD046',
    is_show_trophy: false,
  },
  return: {
    label: 'Return To Player (RTP)',
    secondaryLabel: 'RTP',
    asset_type: 'text',
    text: '',
    icon: '',
    color: '#DBD046',
    is_show_trophy: false,
  },
  avg_money_won_per_spin: {
    label: 'Wins per Spin ($ W/S)',
    secondaryLabel: '$ W/S',
    asset_type: 'text',
    text: '',
    icon: '',
    color: '#089E3D',
    is_show_trophy: true,
  },
  sleeper_slot_rating: {
    label: 'Sleeper Slots',
    secondaryLabel: 'SS',
    asset_type: 'text',
    text: '',
    icon: 'assets/images/sleeper-slots.svg',
    color: '#1D89A5',
    is_show_trophy: false,
  },
  volatility_rating: {
    label: 'Volatility (VOL)',
    secondaryLabel: 'VOL',
    asset_type: 'text',
    text: '',
    icon: 'assets/images/volatility.svg',
    color: '#0A2D8B',
    is_show_trophy: false,
  },
  // time_played: {
  //   label: 'Time Played',
  //   secondaryLabel: 'Time Played',
  //   color: '#8FE3E8',
  //   is_show_trophy: false,
  //   asset_type: 'text',
  //   icon: 'assets/images/time-outline.svg',
  //   text: '',
  // },
  // session_played: {
  //   label: 'Sessions',
  //   secondaryLabel: 'Sessions',
  //   color: '#8FE3E8',
  //   icon: 'assets/images/hourglass-outline.svg',
  //   text: '',
  //   asset_type: 'text',
  //   is_show_trophy: false,
  // },
  pop: {
    label: 'Payout To Player (POP)',
    secondaryLabel: 'POP',
    color: '#8FE3E8',
    icon: 'assets/images/hourglass-outline.svg',
    text: '',
    asset_type: 'text',
    is_show_trophy: false,
  },
};

export const snapshots: SlotMetricsData = {
  snapShotArr: [
    {
      display_order: 1,
      className: 'snap-gray-blue',
      items: [
        {
          id: 1,
          field: 'pop',
          value: 'pop',
          label: '% Payout to Player',
          snapshot_label: 'POP',
          short_name: 'POP',
          description: 'Total payout percentages % returned to player',
        },
        {
          id: 2,
          field: 'return',
          value: 'return',
          label: '% Return to Player',
          snapshot_label: 'Return To Player (RTP)',
          short_name: 'RTP',
          description: '% of money won divided by money played',
        },
        {
          id: 3,
          field: 'spins',
          value: 'spins',
          label: '# Spins',
          snapshot_label: 'Spins',
          short_name: 'Spins',
          description: 'Total number of spins',
        },
        {
          id: 4,
          field: 'volatility_rating',
          value: 'volatility_rating',
          label: '^ Volatility',
          snapshot_label: 'Volatility (VOL)',
          short_name: 'Pers',
          description:
            'Relatively high % (or low %) returned to player and relatively low % (or high %) winning spins',
        },
      ],
    },
    {
      display_order: 2,
      className: 'snap-light-blue',
      items: [
        {
          id: 5,
          field: 'jackpot_value',
          value: 'jackpot_value',
          label: '$ Jackpots',
          snapshot_label: 'Jackpots ',
          short_name: 'Jpots',
          description: 'Total amount of money won from jackpots',
        },
      ],
    },
    {
      display_order: 2,
      className: 'snap-gray-blue',
      items: [
        {
          id: 6,
          value: 'money_won',
          field: 'money_won',
          label: '$ Won',
          snapshot_label: 'Won',
          short_name: 'Won',
          description: 'Total amount of money the slot has paid out irrespective of amount paid in',
        },
        {
          id: 7,
          value: 'money_played',
          field: 'money_played',
          label: '$ Played',
          snapshot_label: 'Played',
          short_name: 'Play',
          description: 'Total money played',
        },
      ],
    },
    {
      display_order: 3,
      className: 'snap-light-blue',
      items: [
        {
          id: 8,
          value: 'avg_money_won_per_spin',
          field: 'avg_money_won_per_spin',
          label: '$ Win per Spin',
          snapshot_label: 'Win per Spin ($ W/S)',
          short_name: '$ W/S',
          description: 'Average amount of money won per spin',
        },
        {
          id: 9,
          value: 'win_rate',
          field: 'win_rate',
          label: '% Winning Spins',
          snapshot_label: 'Winning Spins (% WS)',
          short_name: '% WS',
          description: '% of spins that won money',
        },
        // {
        //     id: 10,
        //     value: 'sleeper_slot_rating',
        //     field: 'sleeper_slot_rating',
        //     label: 'Z Sleeper Slots',
        //     snapshot_label: 'Sleeper Slots (1-6)',
        //     short_name: 'SS',
        //     description:
        //         'Slots that have a relatively high % returned to player with relatively few spins',
        // }
      ],
    },
  ],
};

export const myPlayInsights = [
  // {
  //   id: 11,
  //   value: 'time_played',
  //   field: 'time_played',
  //   label: 'Time Played',
  //   color: '#8FE3E8',
  //   backgroundColor: '#8FE3E8',
  //   className: '',
  //   description: 'Time Played',
  //   display_order: 11,
  //   insightId: 11,
  // },
  // {
  //   id: 12,
  //   value: 'session_played',
  //   field: 'session_played',
  //   label: 'Sessions',
  //   color: '#8FE3E8',
  //   backgroundColor: '#8FE3E8',
  //   className: '',
  //   description: 'Sessions',
  //   display_order: 12,
  //   insightId: 12,
  // },
];

export const insightValues = Insights.map((insight) => insight.value);
