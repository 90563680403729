/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { IonButton, IonImg } from '@ionic/react';
import { IState } from 'appRedux/createStore';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import UpgradeCard from '../components/upgrade-card';

interface IPlans {
  onClickUpgrade: (couponCode: string, pricecode: string) => void;
  goBack: () => void;
}

export const ProUpgrade = ({ onClickUpgrade, goBack }: IPlans) => {
  const [couponCode, setCouponCode] = useState('');
  const slotCheckPriceCode = __SLOTCHECK_PRICE_CODE__;
  const slotcheckPriceCodeTenDays = __SLOTCHECK_TEN_DAY_PRICE_CODE__;
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;
  const onClickBack = () => {
    goBack();
  };
  return (
    <>
      <div className="slot-plan-box">
        <div className="header">
          <div style={{ marginBottom: '20px' }}>
            <IonImg style={{ height: '35px', marginTop: 30 }} src="assets/images/SLOTcheckTM.svg" />
          </div>
        </div>
        <div className="slot-plan-box-content body-bold">
          <div className="play-text">Play in the know with PRO</div>
          <ul>
            <div>
              <li>All 9 Hot Slot Insights</li>
            </div>
            <div>
              <li>All 7 time frames</li>
            </div>
            <div>
              <li>Slot comparison feature</li>
            </div>
          </ul>
        </div>

        {!isProUser && (
          <>
            {/* <UpgradeCard
              title="10-Day Pass"
              price="$4.99"
              description="Full Pro access for 10 days"
              couponCode={couponCode}
              priceCode={slotcheckPriceCodeTenDays}
              onClickUpgrade={onClickUpgrade}
            /> */}

            <UpgradeCard
              title="Monthly PRO Access"
              price="$9.99"
              description="Full Pro access for 30 days"
              couponCode={couponCode}
              priceCode={slotCheckPriceCode}
              onClickUpgrade={onClickUpgrade}
            />
          </>
        )}

        {!isProUser && (
          <div className="slot-plan-box-footer">
            <IonButton class="cancel-btn" fill="outline" onClick={onClickBack}>
              Cancel
            </IonButton>
          </div>
        )}
        {isProUser && <IonButton color="legal">Active plan</IonButton>}
      </div>
    </>
  );
};
