import { IonItem, IonLabel } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import useWhiteLabelStyles, { WHITE_LABEL_TYPE_ENUM } from 'hooks/useWhiteLabelStyles';

export interface IActiveLink {
  to: string;
  children: React.ReactNode;
}

export const ActiveLink: React.FC<IActiveLink> = ({ to, children }: IActiveLink) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  const Iconstyles = useWhiteLabelStyles(WHITE_LABEL_TYPE_ENUM.ICON);

  return (
    <IonItem
      routerLink={to}
      mode="md"
      className={clsx('link', 'body-bold', isActive ? 'is-active' : '')}
    >
      <span style={isActive ? Iconstyles : undefined}>{children}</span>
    </IonItem>
  );
};

export const FavoritesLinkBar = () => {
  const styles = useWhiteLabelStyles(WHITE_LABEL_TYPE_ENUM.CANVAS);
  return (
    <div className="favorites-segment" style={styles}>
      <ActiveLink to="/tabs/favorites">
        <IonLabel>Favorites</IonLabel>
      </ActiveLink>
      <ActiveLink to="/tabs/myPlay">
        <IonLabel>My Play</IonLabel>
      </ActiveLink>
      <ActiveLink to="/tabs/myJackpots">
        <IonLabel>My Jackpots</IonLabel>
      </ActiveLink>
    </div>
  );
};
