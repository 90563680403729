import { Dispatch } from 'react';
import { IAction } from 'appRedux/reducers';

export const AppKey = 'SLOTCHECKSPA-';
export const slotCompareActionTypes = {
  TOGGLE_SELECTED_SLOT: `${AppKey}-TOGGLE_SELECTED_SLOT`,
  START_COMPARING: `${AppKey}-START_COMPARING`,
  STOP_COMPARING: `${AppKey}-STOP_COMPARING`,
  CLEAR_SLOTS: `${AppKey}-CLEAR_SLOTS`,
};
export const SlotCompareReduxCommandCreator = (dispatch: Dispatch<IAction>) => {
  return {
    toggleSelectedSlot(state: { slotNumber: number }) {
      dispatch({
        type: slotCompareActionTypes.TOGGLE_SELECTED_SLOT,
        state,
      });
    },
    startComparing() {
      dispatch({
        type: slotCompareActionTypes.START_COMPARING,
      });
    },
    stopComparing() {
      dispatch({
        type: slotCompareActionTypes.STOP_COMPARING,
      });
    },
    clearSlots() {
      dispatch({
        type: slotCompareActionTypes.CLEAR_SLOTS,
      });
    },
  };
};
