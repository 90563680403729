import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import useLocalStorage from 'common/reactHooks/useLocalStorage';
import React from 'react';

const LegalDisclaimer: React.FC = () => {
  const router = useIonRouter();
  const [, setAgreedToLegalDisclaimer] = useLocalStorage('legalDisclaimer', false);

  const handleDisagree = () => {
    router.push('/logout');
  };

  const handleAgree = () => {
    setAgreedToLegalDisclaimer(true);
    router.push('/auth/choosecasinos');
  };

  return (
    <IonPage className="app-parent m-legal__form">
      <IonHeader>
        <IonToolbar color="legal">
          <IonTitle className="header-title">Slot Machine Outcomes Are Random</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent
        className="ion-padding scroll-disable "
        style={{ backgroundColor: 'white', color: 'black' }}
      >
        <div className="m-legal__form">
          <div style={{ backgroundColor: 'white' }}>
            <p className="text">
              The Slot Machine outcomes are governed by a Random Number Generator (RNG), ensuring
              that each outcome is statistically independent and unpredictable.
            </p>
            <p className="text">
              The historical data provided by Slot Check is solely for informational purposes and
              reflects previous results. Under no circumstances does Slot Check purport to predict,
              influence, or determine future outcomes of slot machine games.
            </p>
            <p className="text">
              The user acknowledges that Slot Check bears no responsibility for the interpretation
              or use of the historical data in a manner that assumes predictability of future slot
              machine results. The user accepts and agrees to this disclaimer as a condition of
              utilizing Slot Check's services.
            </p>
            <div className="button-group" style={{ textAlign: 'right', marginTop: 20 }}>
              <IonButton
                fill="outline"
                color="legal"
                onClick={handleDisagree}
                id="disagreeButton"
                style={{ borderColor: 'blue', color: 'blue', marginRight: '10px' }}
              >
                Disagree
              </IonButton>

              <IonButton
                color="legal"
                onClick={handleAgree}
                id="agreeButton"
                style={{ color: 'white', borderColor: 'blue' }}
              >
                Agree
              </IonButton>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LegalDisclaimer;
export { LegalDisclaimer };
