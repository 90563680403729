/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable security/detect-object-injection */
import { FC } from 'react';
import 'moment';
import 'chartjs-adapter-moment';
import { config, getTimeFrameDetails } from '../../config/config';
import { parseValue } from '../../common/include';
import UpgradeNow from '../UpgradeNow/UpgradeNow';
import SmartLoading from '../SmartLoading';
import { useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';
import { IGraphGameData, ISlotBucketData, ISlotGraphData } from 'appRedux/models/casinoModels';
import BarChartComponent from './BarChartComponent';
interface StateProps {
  timeframe: string;
  insight: string;
  slotPlayData: ISlotGraphData[] | undefined;
  slotSplitData: IGraphGameData[];
  onClickUpgradeNow?: () => void;
  isLoading?: boolean;
  bucketData: ISlotBucketData | undefined;
}

const getChartLabel = (bucketData: ISlotBucketData, insight: string, timeframe: string) => {
  const totalValue = bucketData.jackpot_value || 0;
  const numberOfItems = bucketData.jackpots || 0;
  const lastJackpotValue = parseValue(bucketData.last_jackpot_value || 0, 'jackpot_value');
  const lastJackpotAge = bucketData.long_jackpot_age || '';
  const transformedTimeframeLabel = timeframe;
  const label = numberOfItems === 1 ? 'Jackpot' : 'Jackpots'; 
  const timeFrameDetail = getTimeFrameDetails(timeframe);

  if (insight === 'jackpot_value') {
    if (totalValue) {
      return (
        <div className="chart-label">
          <strong>{numberOfItems}</strong> {label} <strong>{timeFrameDetail.label}</strong>{' '}
          <strong>= {parseValue(totalValue, 'jackpot_value')}</strong>
        </div>
      );
    }

    if (transformedTimeframeLabel === 'months1') {
      return (
        <div className="chart-label no-data">
          No jackpot was paid in the last 30 days. <br/>The last jackpot was{' '}
          <strong>{lastJackpotValue}</strong>; paid <strong>{lastJackpotAge}</strong>.
        </div>
      );
    }

    return (
      <div className="chart-label no-data">
        No jackpot was paid in the last {timeFrameDetail.label}.<br/> The last jackpot was{' '}
        <strong>{lastJackpotValue}</strong>; paid <strong>{lastJackpotAge}</strong>.
      </div>
    );
  }

  return <div className="chart-label no-data">No data in the selected time frame</div>;
};

type SlotDataChartProps = StateProps;

const SlotDataChart: FC<SlotDataChartProps> = (props) => {
  const {
    timeframe,
    insight,
    slotPlayData,
    slotSplitData,
    onClickUpgradeNow,
    isLoading,
    bucketData,
  } = props;
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;
  const isGraphShown = slotPlayData && slotPlayData.length > 0;
  const slotDataValue =
    insight === 'jackpot_value'
      ? slotSplitData.length > 0
        ? slotSplitData
        : slotPlayData
      : slotPlayData;
  if (isLoading) {
    return (
      <div className="slot-chart-box">
        <div className="no-jackpots-container">
          <SmartLoading loading customCls="graph-loader" />
        </div>
      </div>
    );
  }

  if (!isGraphShown) {
    return (
      <div className="slot-chart-box">
        <div className="no-jackpots-container slot-chart-header graph-overlay">
          {bucketData && getChartLabel(bucketData, insight, timeframe)}
          {!isProUser && <UpgradeNow onClick={onClickUpgradeNow} />}
        </div>
      </div>
    );
  }

  return (
    <div className="slot-chart-box">
      {isGraphShown && (
        <>
          <div className="total-insight-value">
            {slotDataValue && (
              <BarChartComponent
                slotData={slotDataValue}
                insight={insight}
                timeframe={timeframe}
                backgroundColor={'#fff'}
                hasNoJackpotsToDisplay={false}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SlotDataChart;
