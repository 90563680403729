import Men from './mapicons/restrooms-boy.png';
import Women from './mapicons/restrooms-girl.png';

export const EurekaIconArr = [
  {
    name: 'Men',
    image: Men,
  },
  {
    name: 'Women',
    image: Women,
  },
];

export const loadImage = (mapbox: mapboxgl.Map, imageName: string, key: string): void => {
  const img = new Image();
  img.src = imageName;

  img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;

    const ctx = canvas.getContext('2d', { willReadFrequently: true }); // Set the flag
    if (ctx) {
      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      mapbox.addImage(key, imageData);
    }
  };

  img.onerror = (error) => {
    console.error('Error loading image:', error);
  };
};

export const addMapEurekaImage = (mapbox: mapboxgl.Map): void => {
  for (let index = 0; index < EurekaIconArr.length; index++) {
    const eachElement = EurekaIconArr[index];
    loadImage(mapbox, eachElement.image, eachElement.name);
  }
};
