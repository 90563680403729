import React from 'react';
import { IonButton } from '@ionic/react';

interface UpgradeCardProps {
  title: string;
  price: string;
  description: string;
  couponCode: string;
  priceCode: string;
  onClickUpgrade: (couponCode: string, priceCode: string) => void;
}

const UpgradeCard: React.FC<UpgradeCardProps> = ({
  title,
  price,
  description,
  couponCode,
  priceCode,
  onClickUpgrade,
}) => {
  return (
    <div className="upgrade-card">
      <div className="title body">
        <span>
          <span className="body-bold">{title}</span>
        </span>

        <div className="price-box">{price}</div>
      </div>

      <div className="body description">{description}</div>

      <IonButton color="legal" onClick={() => onClickUpgrade(couponCode, priceCode)}>
        Select
      </IonButton>
    </div>
  );
};

export default UpgradeCard;
